import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import sceemimage from '../images/sceem-logo.svg';
import './Advertisement.css';
import { Edit } from './EditJson';
import axios from 'axios';
import { config } from '../Config/Config';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import adv from '../images/advertisement.png';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class EditCampaign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            image: "",
            campaignName: "",
            type: "",
            description: "",
            startDate: "",
            endDate: "",
            campaignUrl: "",
            campaignPaymentType: "",
            advertiserName: "",
            advertiserEmail: "",
            advertiserNumber: "",
            location: "",
            typesOfAdvertisement: "",
            url: "",
            paidOrFree: "",
            tireToAdvertise: "",
            payment: "",
            designation: "",
            designationError: '',
            adv: Edit[0],
            publish: true,
            save: false,
            isLoading: true,
            startDateErr: "",
            endDateErr: ""

        }
    }
    // onChange = (e) => {
    //     this.setState({
    //         adv: Object.assign({}, this.state.adv[0], { [e.target.name]: e.target.value })
    //     })
    // }
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    fileHandler = (e) => {
        this.setState({ image: (e.target.files[0]) })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        //console.log(this.state)
    }
    changeStartDate(date) {
        this.setState({ startDateErr: "", startDate: date })
    }
    changeEndDate(date) {
        this.setState({ endDateErr: "", endDate: date })
    }
    componentDidMount = () => {
        this.getCampaignById();
    }
    getCampaignById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + `user/getCampaignById/${this.props.match.params.id}`, { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    // console.log("success!!");
                    self.setState({
                        campaignName: response.data.campaign.campaignName,
                        campaignPaymentType: response.data.campaign.campaignPaymentType,
                        campaignUrl: response.data.campaign.campaignUrl,
                        description: response.data.campaign.description,
                        startDate: response.data.campaign.startDate,
                        endDate: response.data.campaign.endDate,
                        image: response.data.campaign.imageUrl,
                        type: response.data.campaign.type,
                        advertiserName: response.data.campaign.advertiserName,
                        advertiserEmail: response.data.campaign.advertiserEmail,
                        advertiserNumber: response.data.campaign.advertiserNumber,
                        designation: response.data.campaign.designation,
                        isLoading: false
                    })
                    var x = new Date()
                    var todaysDate = x.getFullYear() + '-' + (x.getMonth() + 1) + '-' + x.getDate();
                    var y = new Date(response.data.campaign.startDate)
                    var newStartDate = y.getFullYear() + '-' + (y.getMonth() + 1) + '-' + y.getDate();
                    var z = new Date(response.data.campaign.endDate)
                    var newEndDate = z.getFullYear() + '-' + (z.getMonth() + 1) + '-' + z.getDate();
                    if ((Date.parse(newStartDate) < Date.parse(todaysDate))) {
                        self.setState({ startDateErr: "Start Date should be greater than Today's Date" })
                    } else {
                        self.setState({ startDateErr: "", endDateErr: "" })
                    }
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    editCampaign = data => {
        //console.log("edit", data)
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": `Bearer ${this.state.token}`,
            "applicationType": "web"
        }
        var payload = {
            "campaignId": this.props.match.params.id,
            "isActive": data,
            "campaignName": this.state.campaignName,
            "type": this.state.type,
            "description": this.state.description,
            "startDate": this.state.startDate,
            "imageUrl": this.state.image,
            "endDate": this.state.endDate,
            "campaignUrl": this.state.campaignUrl,
            "campaignPaymentType": this.state.campaignPaymentType,
            "advertiserName": this.state.advertiserName,
            "advertiserEmail": this.state.advertiserEmail,
            "advertiserNumber": this.state.advertiserNumber,
            "designation":this.state.designation
        }
        //console.log("payload", payload)
        var self = this
        axios.post(config.userUrl + 'user/editCampaign', payload, { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status == true) {
                    self.setState({
                        redirect: true
                    })
                    //console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    showPublish = () => {
        document.getElementById('publish').style.display = 'flex'
    }
    showNone = () => {
        document.getElementById('publish').style.display = 'none'
    }
    uploadImage = async (image) => {
        let data = new FormData()
        data.append("file", image)
        data.append("upload_preset", "sceem_")
        data.append("cloud_name", "sceem")
        const resp = await fetch(`https://api.cloudinary.com/v1_1/sceem/image/upload`, {
            method: "post",
            body: data
        })
        let res = await resp.json();
        return res.secure_url
    }

    handleImage = async (e) => {
        //  console.log("fgh", e.target.files)
        if (e.target.files && e.target.files[0]) {
            const res = await this.uploadImage(e.target.files[0]);
            if (res) {
                // console.log("res", res)
                this.setState({ image: res })
            }
        }
    }
    render() {
        const { startDate, endDate, isLoading, redirect, image, imgRef } = this.state
        var a = new Date(startDate)
        var start = parseInt(a.getMonth() + 1) + "-" + a.getDate() + "-" + a.getFullYear();
        var b = new Date(endDate)
        var endD = parseInt(b.getMonth() + 1) + "-" + b.getDate() + "-" + b.getFullYear();

        const selectedStartDate = new Date(start)
        const selectedEndDate = new Date(endD)
        //console.log("start", startDate)

        if (isLoading) {
            return <div className="App"></div>;
        }
        if (redirect) {
            // console.log("redirect")
            return <Redirect to="/advertisement" />
        }
        var x = new Date().toISOString();
        var todaysDate = x;
        var y = new Date(this.state.startDate)
        var newStartDate = y.getFullYear() + '-' + (y.getMonth() + 1) + '-' + y.getDate();
        var z = new Date(this.state.endDate)
        var newEndDate = z.getFullYear() + '-' + (z.getMonth() + 1) + '-' + z.getDate();
        //  console.log("dtes", todaysDate, newStartDate, newEndDate)
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >
                    <Card style={{ width: "80%" }}><br />
                        <h5 style={{ marginLeft: "1.2rem" }}>Edit Campaign</h5><br />
                        <Form style={{ marginLeft: "1.2rem", width: "70%" }} onSubmit={this.handleSubmit}>
                            <label className="poll-date">Campaign Name<span className="red">*</span></label>
                            <div className="form-group">
                                <input type="text"
                                    className="web-font form-control"
                                    aria-describedby="text"
                                    name="campaignName"
                                    value={this.state.campaignName}
                                    onChange={this.onChange}
                                />
                            </div>
                            <div class="row" >
                                <div class="col-md-6">
                                    <label className="poll-date">Type of Advertisement<span className="red">*</span></label>
                                    <select value={this.state.type} name="type" onChange={this.onChange} class="web-font form-control">
                                        <option value="">Select</option>
                                        <option value="commercial">Commercial</option>
                                        <option value="non-commercial">Non-Commercial</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label className="poll-date">Paid / Free</label>
                                    <select value={this.state.campaignPaymentType} name="paidOrFree" onChange={this.onChange} class="web-font form-control">
                                        <option value="">Select</option>
                                        <option value="paid">Paid</option>
                                        <option value="free">Free</option>
                                    </select>
                                </div>
                            </div><br />
                            <label className="poll-date">Description<span className="red">*</span></label>
                            <div className="form-group">
                                <textarea type="text"
                                    className="web-font form-control"
                                    aria-describedby="text"
                                    rows="5"
                                    name="description"
                                    value={this.state.description}
                                    onChange={this.onChange}
                                />
                            </div><br />
                            <div class="row">
                                <div class="col-md-6">
                                    <label className="poll-date">Start Date<span className="red">*</span></label>
                                    <div className="form-group">
                                        <DatePicker
                                            className="web-font form-control form-bg"
                                            selected={selectedStartDate}
                                            minDate={new Date(Date.now())}
                                            dateFormat="dd-MM-yyyy"
                                            onChange={(date) => this.changeStartDate(date)}
                                            filterDate={(date) => date.getDay() === 1}
                                        />
                                        <div className="error">{this.state.startDateErr}</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label className="poll-date">End Date<span className="red">*</span></label>
                                    <div className="form-group">
                                        <DatePicker
                                            className="web-font form-control form-bg"
                                            selected={selectedEndDate}
                                            minDate={new Date(selectedStartDate + 5 * 86400000)}
                                            dateFormat="dd-MM-yyyy"
                                            onChange={(date) => this.changeEndDate(date)}
                                            filterDate={(date) => date.getDay() === 5}
                                        />
                                        <div className="error">{this.state.endDateErr}</div>
                                    </div>
                                </div>
                            </div><br />
                            <label className="poll-date">URL<span className="red">*</span></label>
                            <div className="form-group">
                                <input type="text"
                                    className="web-font form-control"
                                    aria-describedby="text"
                                    name="campaignUrl"
                                    value={this.state.campaignUrl}
                                    onChange={this.onChange}
                                />
                            </div><br />
                            <div className="form-group">
                                <label className="poll-date">Image<span className="red">*</span></label><br />
                                <div style={{ height: "120px", textAlign: "center", objectFit: "contain" }}>
                                    <label htmlFor="upload-button">
                                        {image != "" ? (
                                            <img src={image === "uploads/1649942289609advertisement.png" ? sceemimage : image} style={{ width: "100px", height: "100px" }} />
                                        ) : (
                                            <>
                                                <i class="fa fa-upload fa-2x" style={{ cursor: "pointer" }} aria-hidden="true"></i>
                                                <div className="up-load">Upload Image</div>
                                            </>
                                        )}
                                    </label>
                                    <input
                                        type="file"
                                        style={{ display: "none" }}
                                        id="upload-button"
                                        name="image"
                                        ref={imgRef}
                                        onChange={(e) => this.handleImage(e)}
                                    />
                                </div>
                                {/* <img src={sceemimage} alt="" onClick={() => this.setState({ imageUrl: "uploads/1649942289609advertisement.png" })} className={this.state.imageUrl === "uploads/1649942289609advertisement.png" ? "border-url create-imageurl" : "create-imageurl"} /> */}

                            </div>
                        </Form><br />
                    </Card><br />
                    <Card style={{ width: "80%" }}><br />
                        <div style={{ marginLeft: "1.2rem", width: "75%" }}>
                            <h5 >Advertiser's Details</h5><br />
                            <div class="row">
                                <div class="col-md-6">
                                    <label className="poll-date">Advertiser Name<span className="red">*</span></label>
                                    <input type="text"
                                        className="web-font form-control"
                                        name="advertiserName"
                                        value={this.state.advertiserName}
                                        onChange={this.onChange}
                                        onBlur={this.handleBlur}
                                    />
                                    <div className="div">{this.state.nameError && (
                                        <div className="error">{this.state.nameError}</div>
                                    )}</div>
                                </div>
                                <div className="col-md-6">
                                    <label className="poll-date">Advertiser Designation<span className="red">*</span></label>
                                    <input type="text"
                                        className="web-font form-control"
                                        name="designation"
                                        value={this.state.designation}
                                        onChange={this.onChange}
                                        onBlur={this.handleBlur}
                                    />
                                    <div className="div">{this.state.designationError && (
                                        <div className="error">{this.state.designationError}</div>
                                    )}</div>
                                </div>
                            </div><br />
                            <div class="row">
                                <div class="col-md-6">
                                    <label className="poll-date">Email<span className="red">*</span></label>
                                    <input type="email"
                                        className="web-font form-control"
                                        name="advertiserEmail"
                                        disabled={true}
                                        value={this.state.advertiserEmail}
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div class="col-md-6">
                                    <label className="poll-date">Mobile Number<span className="red">*</span></label>
                                    <input type="number"
                                        className="web-font form-control"
                                        name="advertiserNumber"
                                        value={this.state.advertiserNumber}
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>
                        </div><br />
                    </Card><br />
                    <div className="cancel-right">
                        {/* {((newStartDate > todaysDate) || (newEndDate < newStartDate)) ?
                            <>
                                <Button variant="default" onClick={() => this.editCampaign(this.state.publish)} disabled="true" className="sendreview-btn mt-1 ml-3">Submit </Button>
                                <Button onClick={() => this.editCampaign(this.state.save)} className="save-btn mt-1 ml-3" disabled="true" variant="default">Update</Button></> :
                            <>
                                <Button variant="default" onClick={() => this.editCampaign(this.state.publish)} className="sendreview-btn mt-1 ml-3">Submit </Button>
                                <Button onClick={() => this.editCampaign(this.state.save)} className="save-btn mt-1 ml-3" variant="default">Update</Button></>} */}
                        {/* {(newStartDate > todaysDate) ?
                            <>
                                <Button onClick={() => this.editCampaign(this.state.publish)} variant="default" disabled="true" className="sendreview-btn ml-3">Submit </Button>
                                <Button className="save-btn ml-3" onClick={() => this.editPoll(this.state.save)} disabled="true" variant="default">Update</Button></> : */}
                        {((this.state.startDate < todaysDate) || (this.state.endDate < this.state.startDate) || (this.state.endDate < todaysDate)) ?
                            <>
                                {/* {console.log("today", this.state.startDate, this.state.endDate, todaysDate, this.state.startDate < todaysDate, this.state.endDate < this.state.startDate)} */}
                                {/* {console.log("date", (todaysDate < newEndDate), (newStartDate > todaysDate), (this.state.startDate >= todaysDate), (this.state.endDate >= todaysDate))} */}
                                <Button onClick={() => this.editCampaign(this.state.publish)} variant="default" disabled="true" className="sendreview-btn ml-3">Submit </Button>
                                <Button className="save-btn ml-3" onClick={() => this.editCampaign(this.state.save)} disabled="true" variant="default">Update</Button></> :
                            <>
                                {/* {console.log("today1", this.state.startDate, this.state.endDate, todaysDate, this.state.startDate < todaysDate, this.state.endDate < this.state.startDate)} */}
                                {/* {console.log("date1", (todaysDate < newEndDate), (newStartDate > todaysDate), (this.state.startDate >= todaysDate), (this.state.endDate >= todaysDate))} */}
                                <Button onClick={this.showPublish} variant="default" className="sendreview-btn ml-3">Submit </Button>
                                <div id="publish" className="modal_new">
                                    <form className="modal-content-log">
                                        <div className="md-container">
                                            <p className="mt-2">Do you want to publish the Campaign?</p>
                                            <center><Button variant="default" onClick={() => this.editCampaign(this.state.publish)} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                                <Button variant="default" onClick={this.showNone} className="deletebutton">No</Button></center>
                                        </div>
                                    </form>
                                </div>
                                {/* {(todaysDate > newEndDate || (newStartDate < todaysDate) ) ? */}
                                <Button className="save-btn ml-3" onClick={() => this.editCampaign(this.state.save)} variant="default">Update</Button></>}
                        <Link to="/advertisement"><Button variant="default" className="cancelreview-btn ml-4">Cancel</Button></Link>
                    </div>
                    <br /><br />
                </div>
            </div>
        );
    };
}

