import React, { useParams, useRef, useState, useEffect } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, useRouteMatch, NavLink, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import '../ReviewQueue/ReviewQueue.css';
import orange from '../images/orange-circle.svg';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function AdvertisementRequests(props) {
    const [selected, setSelected] = useState(0)
    const [token, setToken] = useState(simpleCrypto.decrypt(sessionStorage.getItem('junkStore')))
    const [requestById, setRequestById] = useState([])
    const [description, setDescription] = useState('');
    const refText = useRef('myTextarea');
    const [requests, setRequests] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [redirect, setRedirect] = useState(false)

    let { path, url } = useRouteMatch();
    //console.log(path)

    useEffect(() => {
        getAdvertisementByAdmin();
    }, []);
    const getAdvertisementByAdmin = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        var payload = {
            "status": 2
        }
        axios.post(config.userUrl + 'user/getAdvertisementByAdmin', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    //console.log("res", response.data)
                    setRequests(response.data.advertisement)
                    setIsLoading(false)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const getAllUsersAdvRequestsById = (id) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        var payload = {
            "advertisementId": id,
        }
        axios.post(config.userUrl + `user/getAdvertisementById`, payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    setRequestById(response.data.advertisement)
                    // setInterestType(response.data.advertisement[0].type)
                    // setMonthsActive(response.data.advertisement[0].timesOfAdvertisement)
                    // setLikesCount(response.data.advertisement[0].type)
                    // setPollsAnsweredCount(response.data.advertisement[0].type)
                    // setPollsCreatedCount(response.data.pollCreatedCount)
                    // setTopicDetails(response.data.topicDetails)
                    // setUserName(response.data.userName)
                    // setCount(response.data.applyCount)
                    setIsLoading(false)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const acceptAndRejectRequests = (data, flag) => {
        //console.log("edit", data)
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        var payload = {
            "advertisementId": data,
            "status": flag,
            "description": description
        }
        // console.log("payload", payload)
        var self = this
        axios.post(config.userUrl + 'user/advertisementRequestAcceptOrRejectByAdmin', payload, { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status == true) {
                    window.location.href = '/advertisement'
                    //console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };

    if (!sessionStorage.getItem('junkStore')) {
        return <Redirect to='/' />;
    }
    if (isLoading) {
        return <div></div>
    }
    const updatedDate = requests.map((i, idx) => {
        var a = new Date(i.createdAt)
        var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
        return updated;
    })
    const showAccept = () => {
        document.getElementById('accept').style.display = 'flex'
    }
    const showAcceptNone = () => {
        document.getElementById('accept').style.display = 'none'
    }
    const showInfo = () => {
        document.getElementById('info').style.display = 'flex'
    }
    const showInfoNone = () => {
        document.getElementById('info').style.display = 'none'
    }
    const showReject = () => {
        document.getElementById('reject').style.display = 'flex'
    }
    const showRejectNone = () => {
        document.getElementById('reject').style.display = 'none'
    }
    return (
        <div>
            <NavBar />
            <Sidebar />
            <div style={{ marginTop: "5.1rem", marginLeft: "275px" }}>
                <Row style={{ width: "100%" }}>
                    <Col md={4}>
                        <div class="scrolling-area-review">
                            <div class="scrolling-element-inside">
                                <div className="inbox">Requests</div>
                                {requests.length !== 0 ?
                                    <div>
                                        {requests.map((data, i) => (
                                            <div>
                                                <Card style={{ border: "none" }} onClick={() => getAllUsersAdvRequestsById(data.id)}>
                                                    <NavLink to={`${path}/${data.id}`} className="inbox_Card link" activeClassName="_Active" key={i} >
                                                        <Row style={{ width: "100%" }}>
                                                            <Col md={2}>
                                                                <img src={orange} style={{ width: "32px" }} />
                                                            </Col>
                                                            <Col md={7}>
                                                                <div className="article-approved">{data.campaignName} </div>
                                                                <div className="messages">{data.type}</div>
                                                            </Col>
                                                            <Col md={3}>
                                                                <div className="mt-1 time">{updatedDate[i]}</div>
                                                            </Col>
                                                        </Row>
                                                    </NavLink>
                                                </Card>
                                                <hr /></div>
                                        ))}
                                    </div>
                                    : <p>No Requests to Accept or Reject</p>}
                            </div>
                        </div>
                    </Col>
                    <Col md={8}>
                        <Card style={{ height: "85vh" }}>
                            {requestById.map((data, i) => (
                                <Route key={i} path={`${path}/${data.id}`} >
                                    <div>
                                        <div class="scrolling-area-review-queue">
                                            <div class="scrolling-element-inside">
                                                <p className="article-title mt-2">Campaign Name: {data.campaignName}</p>
                                                <div>Times of Advertisement: {data.timesOfAdvertisement}</div>
                                                <div>Type: {data.type}</div>
                                                <div>Payment Type: {data.advertiserPaymentType}</div>
                                                <div>Description: {data.description}</div>
                                                <div>Start date: {data.startDate.split("T")[0].split("-").reverse().join("-")}</div>
                                                <div>End date: {data.endDate.split("T")[0].split("-").reverse().join("-")}</div>
                                                <div>Advertiser Name: {data.advertiserName}</div>
                                                <div>Advertiser Designation: {data.designation}</div>
                                                <div>Advertiser Contact Number: {data.advertiserNumber}</div>
                                                <div>Advertiser Email: {data.advertiserEmail}</div>
                                                <img src={data.imageUrl} style={{ width: "70px" }} />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="mt-3 form-group">
                                                <textarea
                                                    rows={2}
                                                    name="description"
                                                    value={description}
                                                    placeholder="Write your comments"
                                                    onChange={(e) => setDescription(e.target.value)}
                                                    className="form-control"
                                                    style={{ marginLeft: "18px", fontSize: "12px", width: "95%", resize: "none", wordWrap: "break-word", }}
                                                />
                                            </div>
                                            <div className="float_Right mr-3" >
                                                <div>{description !== "" ?
                                                    // <div ><Button variant="default" onClick={() => togglePopup(data.id, 1)} className="sendreview-btn mt-1 ml-4">Accept</Button><Button onClick={() => togglePopup(data.id, 0)} className="save-btn mt-1 ml-3" variant="default">Reject</Button></div> 
                                                    <div ><Button onClick={showAccept} className="sendreview-btn mt-1 ml-4" variant="default">Accept</Button>
                                                        <div id="accept" className="modal_new">
                                                            <form className="modal-content-log">
                                                                <div className="md-container">
                                                                    <p className="mt-2">Do you really want to Accept?</p>
                                                                    <center><Button variant="default" onClick={() => acceptAndRejectRequests(data.id, 1)} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                                                        <Button variant="default" onClick={showAcceptNone} className="deletebutton">No</Button></center>
                                                                </div>
                                                            </form>
                                                        </div>
                                                        <Button variant="default" onClick={showReject} className="save-btn mt-1 ml-3">Reject</Button>
                                                        <div id="reject" className="modal_new">
                                                            <form className="modal-content-log">
                                                                <div className="md-container">
                                                                    <p className="mt-2">Do you really want to Reject?</p>
                                                                    <center><Button variant="default" onClick={() => acceptAndRejectRequests(data.id, 0)} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                                                        <Button variant="default" onClick={showRejectNone} className="deletebutton">No</Button></center>
                                                                </div>
                                                            </form>
                                                        </div>
                                                        <Button onClick={showInfo} className="sendreview-btn mt-1 ml-4" variant="default">Info</Button>
                                                        <div id="info" className="modal_new">
                                                            <form className="modal-content-log">
                                                                <div className="md-container">
                                                                    <p className="mt-2">Do you really want to more information?</p>
                                                                    <center><Button variant="default" onClick={() => acceptAndRejectRequests(data.id, 3)} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                                                        <Button variant="default" onClick={showInfoNone} className="deletebutton">No</Button></center>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div> :
                                                    <div><Button variant="default" disabled="true" className="sendreview-btn mt-1 ml-4">Accept</Button><Button disabled="true" className="save-btn mt-1 ml-3" variant="default">Reject</Button><Button disabled="true" className="sendreview-btn mt-1 ml-4" variant="default">Info</Button></div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Route>
                            ))}
                        </Card>
                    </Col>
                </Row>
            </div >
        </div >
    );
}






