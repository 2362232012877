import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import addimage from '../images/addimage.svg'
import './Advertisement.css';
import sceemimage from '../images/sceem-logo.svg';
import { Edit } from './EditJson';
import axios from 'axios';
import { config } from '../Config/Config';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class PublishAdvertisement extends Component {
    constructor(props) {
        super(props);
        this.imgRef = React.createRef()
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            imageUrl: "",
            image: "",
            campaignName: "",
            type: "",
            description: "",
            startDate: "",
            endDate: "",
            timesOfAdvertisement: "",
            campaignUrl: "",
            campaignPaymentType: "",
            advertiserName: "",
            advertiserEmail: "",
            advertiserNumber: "",
            designation:"",
            url: "",
            paidOrFree: "",
            payment: "",
            adv: Edit[0],
            publish: true,
            save: false,
            isLoading: true,
            home: false

        }
    }
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    fileHandler = (e) => {
        this.setState({ image: (e.target.files[0]) })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        //  console.log(this.state)
    }
    changeStartDate(date) {
        this.setState({ startDate: date })
    }
    changeEndDate(date) {
        this.setState({ endDate: date })
    }
    componentDidMount = () => {
        this.getCampaignById();
    }
    getCampaignById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var payload = {
            "advertisementId": this.props.match.params.id
        }
        var self = this
        axios.post(config.userUrl + 'user/getAdvertisementById', payload, { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status === true) {
                    // console.log("success!!");
                    self.setState({
                        campaignName: response.data.advertisement[0].campaignName,
                        campaignPaymentType: response.data.advertisement[0].advertiserPaymentType,
                        campaignUrl: response.data.advertisement[0].campaignUrl,
                        description: response.data.advertisement[0].description,
                        startDate: response.data.advertisement[0].startDate,
                        endDate: response.data.advertisement[0].endDate,
                        image:response.data.advertisement[0].imageUrl,
                        timesOfAdvertisement: response.data.advertisement[0].timesOfAdvertisement,
                        type: response.data.advertisement[0].type,
                        advertiserName: response.data.advertisement[0].advertiserName,
                        advertiserEmail: response.data.advertisement[0].advertiserEmail,
                        advertiserNumber: response.data.advertisement[0].advertiserNumber,
                        designation: response.data.advertisement[0].designation,
                        isLoading: false
                    })
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    // publishAdvertisement = (e) => {
    //     let headers = {
    //         "Content-Type": 'application/json',
    //         "Authorization": "Bearer " + this.state.token,
    //         "applicationType": "web"
    //     }
    //     var payload = {
    //         "advertisementId":this.props.match.params.id,
    //         "type": this.state.type,
    //         "timesOfAdvertisement": this.state.timesOfAdvertisement,
    //         "advertiserPaymentType": this.state.campaignPaymentType,
    //         "description": this.state.description,
    //         "startDate": this.state.startDate,
    //         "endDate": this.state.endDate,
    //         "campaignUrl":this.state.campaignUrl,
    //         "imageUrl":this.state.imageUrl,
    //         "campaignName": this.state.campaignName,
    //         "advertiserName": this.state.advertiserName,
    //         "advertiserNumber": this.state.advertiserNumber,
    //         "advertiserEmail": this.state.advertiserEmail
    //     }
    //     console.log("payload", payload)
    //     var self = this;
    //     axios.post(config.userUrl + 'user/publishedAdvertisementByAdmin', payload, { headers: headers })
    //         .then(function (response) {
    //             console.log("response", response)
    //             if (response.data.status === true) {
    //                 console.log(response, "publishedAdvertisementByAdmin  created successfully!!")
    //                 self.setState({ home: true })
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log("payload", payload)
    //             console.log(error);
    //             document.getElementById('error').innerText = error
    //         });
    // }
    publishAdvertisement = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var payload = {
            "advertisementId": this.props.match.params.id,
            "campaignName": this.state.campaignName,
            "type": this.state.type,
            "imageUrl": this.state.image,
            "description": this.state.description,
            "startDate": this.state.startDate,
            "endDate": this.state.endDate,
            "campaignUrl": this.state.campaignUrl,
            "campaignPaymentType": this.state.campaignPaymentType,
            "advertiserName": this.state.advertiserName,
            "advertiserEmail": this.state.advertiserEmail,
            "advertiserNumber": this.state.advertiserNumber,
            "designation": this.state.designation
        }
        //  console.log("payload", payload)
        var self = this
        axios.post(config.userUrl + 'user/createCampaign', payload, { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status == true) {
                    self.setState({
                        home: true
                    })
                    //    console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    uploadImage = async (image) => {
        let data = new FormData()
        data.append("file", image)
        data.append("upload_preset", "sceem_")
        data.append("cloud_name", "sceem")
        const resp = await fetch(`https://api.cloudinary.com/v1_1/sceem/image/upload`, {
            method: "post",
            body: data
        })
        let res = await resp.json();
        return res.secure_url
    }

    handleImage = async (e) => {
        //  console.log("fgh", e.target.files)
        if (e.target.files && e.target.files[0]) {
            const res = await this.uploadImage(e.target.files[0]);
            if (res) {
                //  console.log("res", res)
                this.setState({ image: res })
            }
        }
    }
    render() {
        const { startDate, endDate, isLoading, home, type, image, imgRef } = this.state
        var a = new Date(startDate)
        var start = parseInt(a.getMonth() + 1) + "/" + a.getDate() + "/" + a.getFullYear();
        var b = new Date(endDate)
        var endD = parseInt(b.getMonth() + 1) + "/" + b.getDate() + "/" + b.getFullYear();

        const selectedStartDate = new Date(start)
         const selectedEndDate = new Date(endD)
        // console.log("start", startDate)

        if (isLoading) {
            return <div className="App"></div>;
        }
        if (home) {
            return <Redirect to="/advertisement" />
        }
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >
                    <Card style={{ width: "80%" }}><br />
                        <h5 style={{ marginLeft: "1.2rem" }}>Create Campaign</h5><br />
                        <Form style={{ marginLeft: "1.2rem", width: "70%" }} onSubmit={this.handleSubmit}>
                            <label className="poll-date">Campaign Name<span className="red">*</span></label>
                            <div className="form-group">
                                <input type="text"
                                    className="web-font form-control"
                                    aria-describedby="text"
                                    name="campaignName"
                                    value={this.state.campaignName}
                                    onChange={this.onChange}
                                />
                            </div>
                            <div class="row" >
                                <div class="col-md-6">
                                    <label className="poll-date">Type of Advertisement<span className="red">*</span></label>
                                    <select value={this.state.type} name="type" onChange={this.onChange} class="web-font form-control">
                                        <option value="">Select</option>
                                        <option value="commercial">Commercial</option>
                                        <option value="non-commercial">Non-Commercial</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label className="poll-date">Paid / Free</label>
                                    <select value={this.state.campaignPaymentType} name="campaignPaymentType" onChange={this.onChange} class="web-font form-control">
                                        <option value="">Select</option>
                                        <option value="paid">Paid</option>
                                        <option value="free">Free</option>
                                    </select>
                                </div>
                            </div><br />
                            <label className="poll-date">Description<span className="red">*</span></label>
                            <div className="form-group">
                                <textarea type="text"
                                    className="web-font form-control"
                                    aria-describedby="text"
                                    rows="5"
                                    name="description"
                                    value={this.state.description}
                                    onChange={this.onChange}
                                />
                            </div><br />
                            <div class="row">
                                <div class="col-md-6">
                                    <label className="poll-date">Start Date<span className="red">*</span></label>
                                    <div className="form-group">
                                        {/* <DatePicker
                                            className="web-font form-control form-bg"
                                            selected={selectedStartDate}
                                            minDate={new Date(Date.now())}
                                            dateFormat="dd-MM-yyyy"
                                        /> */}
                                        <DatePicker
                                            className="web-font form-control form-bg"
                                            selected={selectedStartDate}
                                            minDate={new Date(Date.now())}
                                            dateFormat="dd/MM/yyyy"
                                            onChange={(date) => this.changeStartDate(date)}
                                            filterDate={(date) => date.getDay() === 1}
                                        />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label className="poll-date">End Date<span className="red">*</span></label>
                                    <div className="form-group">
                                        {/* <DatePicker
                                            className="web-font form-control form-bg"
                                            selected={selectedEndDate}
                                            readOnly
                                            minDate={new Date(selectedStartDate + 5 * 86400000)}
                                            dateFormat="dd-MM-yyyy"
                                            onChange={(date) => this.changeEndDate(date)}
                                            filterDate={(date) => date.getDay() === 5}
                                        /> */}
                                        <DatePicker
                                            className="web-font form-control form-bg"
                                            selected={selectedEndDate}
                                            dateFormat="dd/MM/yyyy"
                                            minDate={new Date(selectedStartDate + 5 * 86400000)}
                                            onChange={(date) => this.changeEndDate(date)}
                                            filterDate={(date) => date.getDay() === 5}
                                            placeholderText="dd/mm/yyyy"
                                        />
                                    </div>
                                </div>
                            </div><br />
                            <label className="poll-date">URL</label>
                            <div className="form-group">
                                <input type="text"
                                    className="web-font form-control"
                                    aria-describedby="text"
                                    name="campaignUrl"
                                    value={this.state.campaignUrl}
                                    onChange={this.onChange}
                                />
                            </div><br />
                            <div className="form-group">
                                <label className="poll-date">Select Image<span className="red">*</span></label><br />
                                {/* <img src={sceemimage} alt="" onClick={() => this.setState({ imageUrl: "uploads/1649942289609advertisement.png" })} className={this.state.imageUrl === "uploads/1649942289609advertisement.png" ? "border-url create-imageurl" : "create-imageurl"} /> */}
                                <div style={{ height: "120px", textAlign: "center", objectFit: "contain" }}>
                                    <label htmlFor="upload-button">
                                        {image != "" ? (
                                            <img src={image === "uploads/1649942289609advertisement.png" ? config.userUrl + "uploads/1649942289609advertisement.png" : image} style={{ width: "100px", height: "100px" }} />
                                        ) : (
                                            <>
                                                <i class="fa fa-upload fa-2x" style={{ cursor: "pointer" }} aria-hidden="true"></i>
                                                <div className="up-load">Upload Image</div>
                                            </>
                                        )}
                                    </label>
                                    <input
                                        type="file"
                                        style={{ display: "none" }}
                                        id="upload-button"
                                        name="image"
                                        ref={imgRef}
                                        onChange={(e) => this.handleImage(e)}
                                    />
                                </div>
                            </div><br />
                        </Form><br />
                    </Card><br />
                    <Card style={{ width: "80%" }}><br />
                        <div style={{ marginLeft: "1.2rem", width: "75%" }}>
                            <h5 >Advertiser's Details</h5><br />
                            <div class="row">
                                <div class="col-md-6">
                                    <label className="poll-date">Advertiser Name<span className="red">*</span></label>
                                    <input type="text"
                                        className="web-font form-control"
                                        name="advertiserName"
                                        value={this.state.advertiserName}
                                        readOnly
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div class="col-md-6">
                                <label className="poll-date">Advertiser Designation<span className="red">*</span></label>
                                    <input type="text"
                                        className="web-font form-control"
                                        name="designation"
                                        value={this.state.designation}
                                        readOnly
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div><br />
                            <div class="row">
                                <div class="col-md-6">
                                    <label className="poll-date">Email<span className="red">*</span></label>
                                    <input type="email"
                                        className="web-font form-control"
                                        name="advertiserEmail"
                                        readOnly
                                        value={this.state.advertiserEmail}
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div class="col-md-6">
                                    <label className="poll-date">Mobile Number<span className="red">*</span></label>
                                    <input type="number"
                                        className="web-font form-control"
                                        name="advertiserNumber"
                                        readOnly
                                        value={this.state.advertiserNumber}
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>
                        </div><br />
                    </Card><br />
                    {/* <Link to="/advertisement"><div className="cancel-right">
                    <Button variant="default" onClick={this.publishAdvertisement} className="sendreview-btn mt-1 ml-3">Submit </Button>
                        <Button variant="default" className="cancelreview-btn mt-1 ml-4">Cancel</Button>
                    </div></Link> */}
                    {((this.state.campaignName !== "") && (this.state.image !== "") && (this.state.type !== "") && (this.state.campaignPaymentType !== "") && (this.state.campaignUrl !== "") && (this.state.startDate !== "") && (this.state.endDate !== "") && (this.state.description !== "") && (this.state.advertiserName !== "") && (this.state.advertiserEmail !== "") && (this.state.advertiserNumber !== "")) ?
                        <div className="cancel-right"><Button variant="default" onClick={this.publishAdvertisement} className="sendreview-btn mt-1 ml-4">Submit</Button><Link to="/advertisement"> <Button variant="default" className="cancelreview-btn mt-1 ml-4">Cancel</Button></Link></div> :
                        <div className="cancel-right"><Button variant="default" disabled={true} className="sendreview-btn mt-1 ml-4">Submit</Button><Link to="/advertisement"><Button variant="default" className="cancelreview-btn mt-1 ml-4">Cancel</Button></Link></div>}
                </div>
            </div>
        );
    };
}

